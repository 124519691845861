export const COLORS = [
  "#98a886",
  "#16B0B2",
  "#585123",
  "#3f8efc",
  "#7b2cbf",
  "#f72585",
  "#f57eb6",
  "#b56576",
  "#c08552",
];
